import { Option } from 'model';

export interface Marketplace {
    address?: Array<MarketplaceAddress>;
    id?: number;
    depositCategory?: string;
    depositValue?: string;
    logo?: string;
    name?: string;
    phone?: string;
    timeZone?: string;
    slug: string;
    active: boolean;
    range: MarketplaceRange;
    businessType: MarketplaceType;
    specialHours: Array<SpecialHour>;
    fromTimeLimitId: string;
    toTimeLimitId: string;
    allowNewClients: boolean;
    allowClientsSelectStaff: boolean;
    vaccines: Array<Option<string>>;
    catsEnabled?: boolean;
    dogsEnabled?: boolean;
    exoticsEnabled?: boolean;
    intakeFormEnabled?: boolean;
    bookingRequestsEnabled?: boolean;
}

export interface SpecialHour {
    id: number;
    name: string;
    date: string;
    closed: boolean;
    businessHours: {
        open: string;
        close: string;
    };
}

export enum MarketplaceType {
    INHOME = 'inhome',
    MOBILE = 'mobile',
    ATHOME = 'athome',
    LOCAL_BUSINESS = 'b&m'
}

export interface MarketplaceAddress {
    id: number;
    addressLineOne: string;
    addressLineTwo: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    latitude: number;
    longitude: number;
}

export interface MarketplaceRange {
    id?: number;
    active: boolean;
    range: string;
}

export const emptyRange: MarketplaceRange = {
    id: undefined,
    active: false,
    range: ''
};

export const emptyMarketplace: Marketplace = {
    businessType: MarketplaceType.LOCAL_BUSINESS,
    id: undefined,
    name: '',
    phone: '',
    slug: '',
    active: false,
    address: [],
    logo: '',
    range: emptyRange,
    specialHours: [],
    fromTimeLimitId: '',
    toTimeLimitId: '',
    allowNewClients: false,
    allowClientsSelectStaff: false,
    vaccines: []
};
